#module-thecheckout-order {
    #thecheckout-address-invoice, #thecheckout-address-delivery {
        .field-firstname {
            margin-right: 5px;
        }
        .customer-addresses {
            text-align: left;
            display: inline-block;
            color: $gray-2;
            a {
                transition: none;
                &:hover {
                    border-bottom-color: $gray-2;
                    border-bottom-width: 2px;
                    padding-bottom: 3px;
                }
            }

        } 
     }
}
