.banners-section{
    &__title {
        @media (max-width: 1530px) {
            font-size: font-size(5);
        }
    }
    &__desc {
        @media (max-width: 1530px) {
            font-size: font-size(6);
        }
    }
    .banners-section {
        &__box {
            @media (max-width: 1530px) {
                min-height: 80px;
            } 
        }
    }
}