#product {
    
    /* Product title */
    h1.namne_details {
        font-size: font-size(2);
        font-weight: font-weight(base);
        line-height: 1.3;
        margin: 0;
        margin-bottom: 15px;

        @include media-up(lg) {
            margin-bottom: 30px;
        }
    }

    /* Product short infos */
    .product-information {
        .product-desc {
            font-size: font-size(6);
            color: $gray-3;

            p {
                color: $gray-3;
            }
        }
    }

    /* Avaibility label */
    #product-availability {
        display: inline-flex;
        align-items: center;
        font-weight: font-weight(base);
        margin: 0;
        margin-bottom: 15px;

        @include media-up(lg) {
            margin-bottom: 30px;
        }

        .material-icons {
            height: 100%;
            margin-right: 5px;
        }
    }
}