.custom-container-full {
    padding-left: 15px;
    padding-right: 15px;
    width: 96%;
    margin: 0 auto;
    @media (max-width: 767px) {
        width: 100%;
    }
}

.custom-container-large {
    padding-left: 15px;
    padding-right: 15px;
    width: 96%;
    #index #main & { // póki co tylko na stronie głównej w sekcjach - bez stopki !
        @media (max-width: 991px) {
            width: 100%;
        }
        @media (min-width:992px) and (max-width:1199px) {
            width: 800px;
        }
        @media (min-width:1200px) and (max-width:1399px) {
            width: 1000px;
        }
        @media (min-width:1400px) and (max-width:1699px) {
            width: 1200px;
        }
    }

    @media (min-width:1700px) {
        width: 1530px;
    }
}

.custom-container-default {
    width: 1100px;
}

.custom-container-small {
    width: 900px;
}
.custom-container-full,
.custom-container-large,
.custom-container-default,
.custom-container-small {
    margin: 0 auto;
    max-width: 100%;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
.custom-container-large,
.custom-container-default,
.custom-container-small {
    padding-left: 15px;
    padding-right: 15px;
}

.home-section {
    margin-top: 0px;
    margin-bottom: 100px;

    @media (max-width: 1530px) {
        margin-bottom: 75px;  
    }

    @include media-down(md) {
        margin-bottom: 50px;
    }
}