/* Base */
@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/typography';
@import 'base/global';
@import 'base/extends';
@import 'base/animations';

/* Libraries */
@import 'libs/photoswipe';
@import 'libs/slickslider';

/* Components */
@import 'components/alerts';
@import 'components/buttons';
@import 'components/modal';
@import 'components/custom-modal';
@import 'components/breadcrumbs';
@import 'components/filters';
@import 'components/pagination';
@import 'components/customization-modal';
@import 'components/forms';
@import 'components/related-products-slider';

/* Modules */
@import 'modules/posslider';
@import 'modules/postcategoryslider';
@import 'modules/searchwidget';
@import 'modules/currencyselector';
@import 'modules/languageselector';
@import 'modules/blockcart';
@import 'modules/blockcart-modal';
@import 'modules/userinfo';
@import 'modules/headerbanner';
@import 'modules/hometopmobile';
@import 'modules/featuredproducts';
@import 'modules/block-social.scss';
@import 'modules/sharebuttons.scss';
@import 'modules/emailsubscription.scss';
@import 'modules/shop-features';
@import 'modules/socialfollow';
@import 'modules/banners-section';
@import 'modules/thecheckout';
@import 'modules/paypal';
@import 'modules/wirepayment';
@import 'modules/furgonetka';
@import 'modules/hiadvancedgiftwrapping';
@import 'modules/seigicookie';
@import 'modules/layerslider';

/* Partials */
@import 'partials/header';
@import 'partials/header-checkout';
@import 'partials/footer';
@import 'partials/product';
@import 'partials/product-flags';

/* Views */
@import 'views/home';
@import 'views/category';
@import 'views/category/order-select';
@import 'views/cart';
@import 'views/checkout';
@import 'views/checkout/global';
@import 'views/checkout/login-form';
@import 'views/checkout/account';
@import 'views/checkout/address';
@import 'views/checkout/shipping';
@import 'views/checkout/payment';
@import 'views/checkout/cart-summary';
@import 'views/checkout/order-message';
@import 'views/checkout/agreements';
@import 'views/checkout/header';
@import 'views/checkout/confirm';

@import 'views/product';
@import 'views/product/actions';
@import 'views/product/breadcrumbs';
@import 'views/product/description';
@import 'views/product/discounts';
@import 'views/product/features';
@import 'views/product/gallery';
@import 'views/product/information';
@import 'views/product/not-available';
@import 'views/product/prices';
@import 'views/product/variants';
@import 'views/product/variations-products';
@import 'views/product/historical-price';

@import 'views/order-confirmation';
@import 'views/forgotten-renew-password';
@import 'views/page-addresses';
@import 'views/page-authentication';
@import 'views/page-customer-account';
@import 'views/page-my-account';
@import 'views/page-order-detail';
@import 'views/page-order';
@import 'views/page-not-found';
@import 'views/page-stores';