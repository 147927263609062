.btn {
    text-transform: uppercase;
    font-weight: font-weight(bold);
    // padding: 1.5em 2em;
    padding: 1em 1.5em;
    font-size: font-size(6, mobile);
    border-radius: 0; 
    letter-spacing: 2px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    @include media-up(lg) {
        font-size: font-size(6);
        padding: 1em 2em;
        letter-spacing: 2px;  // z wizualizacji
    }
}

.btn-primary {
    background: $secondary;
    color: $white !important;
    &:hover, &:focus, &:focus:active {
        background: darken($secondary, 10%);
    }
}

.btn-outline {
    color: $black;
    border: 2px solid currentColor;
    background-color: transparent;
    &:hover, &:focus, &:focus:active {
        border-color: $secondary !important;
        background-color: $secondary;
        color: #fff;
    }
    &-alt {
        color: #fff;
    }
}

.btn-muted {
    color: $gray-2 !important;
    border-color: $gray-2;
    &:hover {
        background: $gray-2;
        color: $gray-3 !important;
        border-color: $gray-2 !important;
    }
}

.btn-link{
    color: $black;
    font-size: font-size(6, mobile);
    text-transform:uppercase;
    font-weight: font-weight(semi-bold);
    line-height: 1.25;
    position: relative;
    padding-top: 7px;
    padding-bottom: 7px;
    letter-spacing: 1.2px;
    display: inline-block;
    @include media-up(lg) {
        font-size: font-size(6);
        letter-spacing: 1.4px; // z wizualizacji
    }
    &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $primary;
        transition: transform 0.3s;
    }
    &:hover {
        &:after {
            transform: scaleX(1.1);
        }
    }
}
.btn-muted-link {
    background: none;
    border: none;
    color: $gray-2;
    text-decoration: underline;
    padding: 1em 2em;
    letter-spacing: 1px;
    font-weight: font-weight(semi-bold);
    &:hover, &:focus {
        color: $gray-3 !important;
        text-decoration: underline !important;
    }
}

.btn-lg {
    padding: 1.5em 3em;
}

.btn-light {
    color: $white;
    border-color: $white;
}


.btn-container-outline a {
    @extend .btn;
    @extend .btn-outline;
}

.btn-container-primary a {
    @extend .btn;
    @extend .btn-primary;
}

.btn-container-link a {
    @extend .btn-link;
}

.btn-container-light a {
    @extend .btn-light;
}