.active_filters {
  // background: #dededd;
  padding: .625rem 0;
  margin-bottom: 10px;
  .active-filter-title {
    display: none;
  }
  ul {
    display: inline
  }
  .filter-block {
    padding: 5px 10px;
    display: inline-block;
    font-size: font-size(6);
    vertical-align: baseline;
    // box-shadow: none;
    line-height: 1.2;
    background: $gray-1;
    margin-bottom: 5px;
    &:last-child {
      margin-right: 30px;
    }
  }
  @include media-down(md) {
    .clear-all-wrapper {
      display: block;
      width: 100%;
      margin-top: 5px;
    }
  }
  .js-search-filters-clear-all {
    padding: 5px 10px;
  }
  #_desktop_search_filters_clear_all {
    display: inline-block;
  }
  .filter-block .close, .js-search-filters-clear-all .material-icons {
    font-size: .9375rem;
    opacity: 1;
    margin: 0;
  }
  .filter-block .close {
    margin-left: .3125rem;
  }
  // .js-search-filters-clear-all .material-icons {
    
  // }
}

#search_filters {
  .facets-list {
    clear: both;
    @media (min-width: 992px) {
      display: flex;
    }
  }
  [data-toggle=modal] {
    @extend .btn;
    @extend .btn-outline;
    display: block;
    padding: 5px 40px;
  }
  // h4 {
  //   position: relative;
  //   display: block;
  //   color: #333;
  //   border-bottom: 2px solid #ededed;
  //   margin: 0;
  //   padding: 0 0 10px 0;
  //   line-height: 1;
  //   font-size: 18px;
  //   font-weight: 600;
  //   margin-bottom: 20px;
  //   padding: 20px 0 !important;
  // }

  .facet {
    
    position: relative;
    @media (max-width: 991px) {
 
      margin-bottom: 0;
      // &:not(:last-child) {
        border-bottom: 1px solid $gray;
      // }
    }

    .dropdown-menu {
        padding: 15px;
        width: max-content;
        position: absolute;
        left: 0;
        top: 100%;
        box-shadow: 5px 5px 40px 0 rgba(0, 0, 0, .1);
        background-color: $white;
        z-index: 999;
        border: none;
        margin: 0;
    }
   

    .dropdown-toggle:after, .collapse-toggle:after  {
      content: "\f107";
      font-family: "FontAwesome";
      border: none;
      margin-left: 5px;
      margin-right: 5px;
      line-height: 45px;
      @include media-down(sm) {
        font-size: font-size(3);
      }
    }
    .dropdown-toggle:after {
      margin-right: 25px;
    }

    .title {
      cursor: pointer;
      user-select: none;
      @media (max-width:991px) {
        justify-content: space-between;
      }
    }

    .facet-title {
      width: auto;
      overflow: auto;
      text-overflow: unset;
      position: relative;
      display: block;
      color: #323232;
      margin: 0;
      padding: 0;
      line-height: 45px;
      font-size: font-size(6);
      @include media-down(sm) {
        font-size: font-size(4);
      }
      font-weight: 500;
      margin-bottom: 0;
    }

    .custom-checkbox input[type="checkbox"] + span {
      margin: 0;
      width: 15px;
      height: 15px;
      border: 1px #dadada solid;

      &.color, &.texture {
        border: 0;
        border-radius: 50%;
        width: 25px;
        height: 25px;
      }
    }

    .facet-label {
      margin: 5px 0;
      display: flex;
      &.active {
        font-weight: font-weight(bold);
        .custom-checkbox input[type="checkbox"]+span {
          border: 1px solid $black;
        }
      }

      .custom-checkbox, .custom-radio {
        top: 0;
        margin-right: 8px;
      }


      a {
        color: #333;
        font-size: font-size(6);
        @include media-down(sm) {
          font-size: font-size(5);
        }
        margin-top: 0;
        width: auto;
        overflow: auto;
        text-overflow: unset;

        &:hover {
          color: $main;
        }
      }
    }
  }
}

.facet-dropdown {
  color: #7a7a7a;
  padding-left: 0;
  padding-right: 0;
  background: #f1f1f1;
  border: 3px solid transparent;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px 1px #f1f1f1
}
.facet-dropdown.open {
  border: 0
}
.facet-dropdown.open>.select-title {
  border: 3px solid #333333;
  background: #f6f6f6
}
.facet-dropdown .select-title {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 0;
  width: 100%;
  /* color: #232323; */
  background: #f1f1f1;
  padding: .625rem .625rem .625rem 1.25rem;
  cursor: pointer
}
.facet-dropdown .select-title>i {
  margin-left: auto
}
.facet-dropdown .select-list {
  display: block;
  /* color: #232323; */
  background: #f6f6f6;
  padding: .625rem 1.25rem
}
.facet-dropdown .select-list:hover {
  background: #333333;
  color: #fff;
  text-decoration: none
}
.facet-dropdown .dropdown-menu {
  padding: 0;
  margin-top: 3px;
  left: auto;
  width: 100%;
  background: #f6f6f6;
  border: none;
  box-shadow: 1px 1px 1px 1px #f1f1f1
}


// MODAL 
#facetsModal {
  background-color: $white;
  .modal-dialog {
    margin: 0;
    max-width: 100%;
  }
  .modal-header {
    @extend .custom-modal-title;
  }
  .modal-title {
    position: relative;
    .title {
      line-height: 1;
      letter-spacing: 1px;
      font-weight: font-weight(semi-bold);
    }
  }
  .close {
    @extend .custom-modal-close;
    opacity: 1;
  }
  .modal-content {
    border: none;
  }
  .modal-footer {
    border: none;
    text-align: center;
  }
  .facet_ul li {
    margin-bottom: 10px;
  }
 
}