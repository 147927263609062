#cart {
    #header { position: static; }
    #content-wrapper {
        margin-top: 60px;

        @include media-down(sm) {
            margin-top: 0;
        }
    }

    .h3 {
        letter-spacing: 1px;
    }
    .cart-summary {
        position: sticky;
        top: 150px;
        background: $gray-1;
        padding: 30px;
        .separator {
            border-color: $gray-2;
        }
    }
    

    .block-promo {
        .cart-summary-line .label, .promo-name {
            color: $secondary;
        }

        .promo-code {
            padding: 0;
        }

        .promo-highlighted{
            padding-left: 0;
            font-weight: 600;
        }
        .promo-discounts{
            margin-top: 0;
        }
        
        .promo-code-button {
            padding-left: 0;
            margin-bottom: 0;
            color: $gray-3;
        }

        .promo-input + button {
            margin: 0;
        }

        form {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .promo-input {
                margin-right: 15px;
            }
        }
    }

    .cart-detailed-actions {
        padding-top: 30px;
        padding-bottom: 0;
        .btn-primary {
            display: block;
        }
    }

    .cart-detailed-totals {
        &-block {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .card-block {
        padding-left: 0;
        padding-right: 0;
    }

    .cart-items {
        img {
            max-height: 100px;
        }
        .product-line-info-attributes {
            font-size: font-size(6);
        }

        .remove-from-cart {
            color: $gray-2;
            .material-icons {
                font-size: 20px;
            }
        }

        .cart-item {
            &:not(:last-child) {
                border-bottom: 1px solid $gray-1;
            }
        }
        .input-group {
            &.bootstrap-touchspin {
                @include media-down(sm) {
                    display: flex !important;
                }
            }
        }
    }
    .btn-muted-link {
        
        @include media-up(md) {
            padding-left: 0;
        }

        @include media-down(sm) {
            display: block;
            width: 100%;
            text-align: center;
        }
    }
}