#product {
    .product-actions {
        .control-label {
            /* Display label on whole line */
            display: block;

            /* reset theme.css */
            margin: 0;
        }
    }

    .product-add-to-cart {

        /* Hide quantity field */
        .control-label, .product-quantity .qty {
            display: none;
        }

        /* Reset theme.css */
        .add {
            margin: 0 auto;
            float: none;
        }

        .add-to-cart {
            /* reset theme.css */
            height: auto;
            line-height: inherit;
            padding: 1em 2em;

            /* Display text & icon centered vertically */
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-down(sm) {
                width: 100%;
            }

            /* Icon element */
            .icon {
                background: url(../img/icon-add-to-cart.svg);
                width: 1.5em;
                height: 1.5em;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                display: inline-block;
                margin-right: 1em;
            }

            /* Hide button if combination not available */
            &:disabled {
                display: none;
            }
        }
    }
}