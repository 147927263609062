.products{
    ul {
        li {
            &.product-flag {
                top: 0.5rem;
                right: 0.5rem;
                box-shadow: none;
                border-radius: 0.25em;
                letter-spacing: 1px;
                font-size: 10px;
                font-weight: 400;

                &:first-child {
                    margin-top: 0;
                }

                &:not(:first-child) {
                    margin-top: 1rem !important;
                }

                @include media-up(lg) {
                    top: 1rem;
                    right: 1rem;
                    font-size: 1em;
                    font-weight: 600;
                }
            }
        }
    }

    ul{
        &.product-flag{
            position: absolute;
            top: 0;
            right: 0;
            display: grid;
            justify-items: end;
            li{
                &.product-flag{
                    width: fit-content;
                    position: static;
                }
            }
        }
    } 
}