#module-thecheckout-order {
    .page-content {
        box-shadow: none !important;
        border: none !important;
    }

    label {
        margin-bottom: 0;
    }
    .form-group {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        padding-left: 0;
        padding-right: 0;
        &.missing-street-number{
            label {
                &[data-missing-street-nr-notice] {
                    &:after {
                        margin-top: 5px;
                        text-align: left;
                    }
                }
            }
        }
        // &.email {
        //     margin-bottom: 10px;
        // }

        // &.countrySelect {
        //     margin-top: 10px;
        //     margin-bottom: 10px;
        // }

        .field-label {
            margin-bottom: 5px;
            display: inline-block;
            // font-size: font-size(6);
            // @include media-down(md) {
            //     font-size: font-size(5);
            // }
        }

        // .custom-checkbox {
        //     .label {
        //         font-size: 12px;
        //         @include media-down(md) {
        //             font-size: 13px;
        //             padding-top: 0;
        //         }
                
        //     }
        // }

        // &.checkbox {
        //     margin-bottom: 5px;
        // }
    }

    .page-content {
        .has-float-label {
            select {
                &.form-control {
                    padding-top: 0.5rem;
                    background-size: 9px 26px;
                }
            }
        }
    }

    .custom-checkbox {
        input{
            &[type=checkbox]{
                + span {
                    width: 15px;
                    height: 15px;
                    min-width: 15px;
                    margin-top: 3px;
                    margin-right: 5px;
                }
            }
        }
    }
    .custom-radio {
        input[type=radio]{
            &:checked {
                + span {
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .block-header {
        font-size: font-size(5);
        font-weight: font-weight(semi-bold);
        margin-bottom: 20px;
        padding: 0;

        @include media-up(lg) {
            &:before {
                display: none;
            }
        }
    }

    .checkout-block {
        border: 1px solid $gray-1;
        @include media-up(lg) {
            margin-left: 15px;
            margin-right: 15px;
        }
        .inner-area {
            padding: 15px;
        }
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}