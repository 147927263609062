#product{
    .product-block-wrapper:has(.historical-price){
        .product-prices {
            margin: 0 0 10px 0;

            @include media-up(lg) {
                margin-bottom: 5px;
            }
        }
        .historical-price{
            margin-bottom: 15px;
            font-size: 0.85rem;
            .value{
                font-weight: 600;
            }
            @include media-up(lg) {
                margin-bottom: 30px;
            }
        }
    }
}