.hi-gift-cart-container{
    box-shadow: none;
    border: none;
    .hi-gift-wrapper-content{
        margin-top: 2rem;
        .card-block{
            margin: 15px auto;
            padding: 0;
            .page-heading{
                font-size: 1.4rem;
                font-weight: 600;
                letter-spacing: 1px;
            }
        }
        .hi-gift-wrapping-products{
           border: 1px solid rgba(0, 0, 0, .2);
        }
    }
}