
#authentication, #identity {
    #content {
        background: $white;
        font-size: .875rem;
        max-width: 1000px;
        margin: 2rem auto;
        border: 1px solid $gray-1;
        padding: 2rem 30px;
    }
    .tooltip {
        &.tooltip-bottom {
            padding: 0;
            margin: 0
        }
    }
    .custom-checkbox {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        span {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 0.9375rem;
            flex: 0 0 0.9375rem
        }
        label {
            padding-left: .625rem;
        }
    }
}
#authentication .radio-inline,
#identity .radio-inline {
    padding: 0
}
#authentication .radio-inline .custom-radio,
#identity .radio-inline .custom-radio {
    margin-right: 0
}