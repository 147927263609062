.user-info-block {
    a {
        padding-right: 0;
    }
    ul {
        display: inline-flex;
        align-items: center;
    }
    .logout {
        color: $gray-2;
    }
}