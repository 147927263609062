.home-slider {
   @extend .custom-container-full;
   @extend .home-section;

   @include media-down(lg) {
       display: none !important;
   }
}

.home-slide {
    position: relative;
    &:focus {
        outline: none;
    }
    img {
        max-height: 750px;
        width: 100%;
        object-fit: cover;
    }
}

.custom-slide {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    .h1, .h2, .h3 {
        line-height: 1;
    }
}

.custom-slide-1 {
    a {
        @extend .btn;
        @extend .btn-outline;
    }
    .col-md-6 {
        &:nth-child(2) {
            a {
                @extend .btn-light;
            }
        }
    }
    .h2 {
        font-size: 100px;
        letter-spacing: 20px;
        margin-bottom: 20px;
    }

    .h3 {
        font-weight: 500;
        letter-spacing: 2px;
        margin-bottom: 10px;
    }

    .h4 {
        margin-bottom: 30px;
        font-weight: 500;
        line-height: 1.4;
    }

    p {
        width: 500px;
        margin: 0 auto;
    }

    @media (max-width: 1530px) {
        .h2 {
            font-size: 80px;
        }
    }
}