.customization-modal .modal-content {
    border-radius: 0;
    border: 1px solid #f6f6f6
}
.customization-modal .modal-content .modal-body {
    padding-top: 0
}
.customization-modal .modal-content .modal-body .product-customization-line {
    padding-bottom: .9375rem;
    padding-top: .9375rem;
    border-bottom: 1px solid #f6f6f6
}
.customization-modal .modal-content .modal-body .product-customization-line .label {
    font-weight: 700;
    text-align: right
}
.customization-modal .modal-content .modal-body .product-customization-line:last-child {
    padding-bottom: 0;
    border-bottom: 0
}