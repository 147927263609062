.social-sharing {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 1.5rem
}
.social-sharing ul {
    margin-bottom: 0
}
.social-sharing li {
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .2);
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: inline-block;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    background-position: .25rem .25rem;
    cursor: pointer;
    margin-left: .5rem
}
.social-sharing li a {
    display: block;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden
}
.social-sharing li a:hover {
    color: transparent
}