#product {
    .product-not-available {
        padding-top: 15px;
        border-top: 1px solid $gray-1;

        @include media-up(md) {
            padding-top: 30px;
        }

        .alert {
            margin-top: 10px;
        }

        strong {
            margin-bottom: 5px;
            display: inline-block;
        }

        input {
            margin-bottom: 15px;
        }

        @include media-down(md) {
            .btn {
                display: block;
                width: 100%;
            }
        }
    }
}