.home-top-mobile {
    position: relative;
    height: 85vh;
    height: calc(85vh - #{$navbar-height-mobile});
    @include media-up(lg) {
        display: none !important;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    &__content {
        display: block;
        width: 80%;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        color: $white;
        p {
            color: currentColor;
            margin-bottom: 0;
        }

        .h2 {
            font-size: 50px;
        }
        .h3 {
            font-size: 20px;
            line-height: 1.1;
        }
        .h4 {
            font-size: 13px;
            line-height: 1.4;
            width: 250px;
            display: block;
            margin: 0 auto;
        }
        .btn {
            margin-top: 40px;
        }
        a {
            @extend .btn;
            @extend .btn-outline;
            @extend .btn-light;
        }
    }
    .scroll-down {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        text-align: center;

        .icon {
            padding: 15px;
            display: inline-block;
            height: 20px;
            width: 20px;
            background: url('../img/icon-chevron-down.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            span {
                display: none;
            }
        }
    }
}