h1, .h1 {
    font-size: font-size(1, mobile);
    line-height: line-height(1, mobile);
    font-weight: font-weight(semi-bold);

}

h2, .h2 {
    font-size: font-size(2, mobile);
    line-height: line-height(2, mobile);
    font-weight: font-weight(base);

}

h3, .h3 {
    font-size: font-size(3, mobile);
    line-height: line-height(3, mobile);
    font-weight: font-weight(semi-bold);
}

h4, .h4 {
    font-size: font-size(4, mobile);
    line-height: line-height(4, mobile);
    font-weight: font-weight(base);
}

h5, .h5 {
    font-size: font-size(5, mobile);
    line-height: line-height(5, mobile);
    font-weight: font-weight(semi-bold);
}

h6, .h6 {
    font-size: font-size(6, mobile);
    line-height: line-height(6, mobile);
    font-weight: font-weight(base);
}  

@include media-up(lg) {
    h1, .h1 {
        font-size: font-size(1);
        line-height: line-height(1);
    }

    h2, .h2 {
        font-size: font-size(2);
        line-height: line-height(2);
    }

    h3, .h3 {
        font-size: font-size(3);
        line-height: line-height(3);
    }

    h4, .h4 {
        font-size: font-size(4);
        line-height: line-height(4);
    }

    h5, .h5 {
        font-size: font-size(5);
        line-height: line-height(5);
    }

    h6, .h6 {
        font-size: font-size(6);
        line-height: line-height(6);
    }
}
    


h2 {
    letter-spacing: 2.9px;  // z wizualizacji
}
h3 {
    letter-spacing: 2.3px;  // z wizualizacji
}
h5 {
    letter-spacing: 0.8px;  // z wizualizacji
}
@media (max-width: 767px) {
    h2, h3 {
        letter-spacing: 1.8px;
    }
    h5 {
        letter-spacing: 0.65px;
    }
}

a {
    color: inherit;
    &:hover, &:focus {
        color: $secondary;
    }
}


.text-primary {
    color: $primary!important
}

.text-secondary {
    color: $secondary!important
}