
#module-thecheckout-order {
    #thecheckout-cart-summary {
        .cart-summary-line {
            margin-bottom: 5px;
        }
        .cart-detailed-totals-wrapper {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        [data-link-action="x-remove-voucher"] {
            color: $black;
            font-size: 40px;
            padding: 5px;
        }
        
        .promo-name {
            .cart-summary-line {
                display: flex;
                align-items: center;
            }
        }
    }
}
