#product {
    .breadcrumb_container {
        margin-top: 30px;

        /* Disable breadcrumbs on mobile */
        @include media-down(md) {
            margin-top: 15px;
            .breadcrumb {
                margin-bottom: 15px;
            }
        }
        @include media-down(sm) {
            display: none;
        }
    }
}