#module-thecheckout-order {
    #thecheckout-confirm {
        .condition-label {
            font-weight: font-weight(semi-bold);
            a {
                text-decoration: underline;
            }
        }
        #confirm_order {
            margin-top: 15px;
        }
    }
}