.slick-slide {
    &:focus {
        outline: none;
    }
    
}



.slick-dots {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    transform: translateY(100%);
    > li {
        width: 15px;
        height: 15px;
        &:not(:last-child) {
            margin-right: 15px;
        }
        button {
            text-indent: -9999px;
            font-size: 0px;
            white-space: nowrap;
            overflow: hidden;
            width: 15px;
            height: 15px;
            background: $primary;
            border-radius: 50%;
            border: none;
            display: block;
        }
        &.slick-active {
            
            button {
                background: $secondary;
            }
            
        }
    }
}