.product-features {
    /* Display block same size in a row */
    display: flex;
    flex-wrap: wrap;

    /* Display blocks in 2 columns grid */
    .product-features-item {
        width: 100%;
        margin-bottom: 15px;

        @include media-up(sm) {
            width: 50%;
        }

        @include media-up(lg) {
            margin-bottom: 30px;

            /* Small offset for second column */
            &:nth-child(2n) {
                padding-left: 5%;
            }
        }
    }

    /* Name and value in new lines */
    .feature-name,
    .feature-value {
        display: block;
    }

    .feature-value {
        color: $gray-2;
    }
}