.page-index {
  .breadcrumb_container {
    display: none;
  }
}

// On pages (not product listing pages)
.breadcrumb_container {
  overflow: hidden;
  // background: #f6f6f6;
   
  .breadcrumb {
    border: 0;
    padding: 5px 0 8px !important;
    margin-bottom: 2rem;
    margin-top: 1rem;
    ol {
      padding-left: 0;
      margin-bottom: 0;

      li {
        display: inline;

        &:after {
          content: ">";
          color: #333;
          margin: 0.3125em;
          font-size: 12px;
        }

        &:last-child {

          a,
          span {
            color: $secondary;
          }

          &:after {
            content: "";
          }
        }

        a {
          display: inline-block;
          position: relative;
          color: #333;
          line-height: 25px;

          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }

  .breadcrumb[data-depth="1"] {
    display: none;
  }
}

// Global
.breadcrumb {
  padding: .75rem 1rem;
  font-size: font-size(6);
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0;

  &:after {
    content: "";
    display: table;
    clear: both
  }
}
.breadcrumb-item {
  float: left;

  &+.breadcrumb-item {
    &:before {
      display: inline-block;
      padding-right: .5rem;
      padding-left: .5rem;
      color: #f1f1f1;
      content: "/"
    }
    &:hover {
      &:before {
        text-decoration: underline;
        text-decoration: none
      }
    }
  }
  &.active {
    color: #f1f1f1
  }
}


// On product listing cover
.category-cover .breadcrumb {
  background: none;
  padding: 0
}

.category-cover .breadcrumb[data-depth="1"] {
  display: none
}

.category-cover .breadcrumb ol {
  padding-left: 0;
  margin-bottom: 0
}

.category-cover .breadcrumb li {
  display: inline
}

.category-cover .breadcrumb li:after {
  content: "/";
  margin: .3125rem
}

.category-cover .breadcrumb li:last-child {
  content: "/";
  margin: 0;
  pointer-events: none;
}

.category-cover .breadcrumb li:last-child:after {
  content: ""
}

/* Home category */

body.category-id-2 {
  .breadcrumb {
    display: none;
  }
}