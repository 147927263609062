body{
    &:not(#index,#product){
        #footer {
            margin-top: 2rem;
        }
    } 
}
#footer {
    padding-top: 2.5rem;
    border-top: 1px solid $gray-1;
    @include media-down(md) {
        padding-top: 40px;
    }
    .ft_newsletter {
        .alert {
            margin-top: 15px;
            font-size: font-size(5);
        }
    }
    .footer-bootm {
        @media (max-width: 450px) {
            .payment {
                max-width: 45px;
                display: flex;
                float: none;
            }
        }
    }
}
.footer-container {
    margin-top: 1.25rem;
    padding-top: 1.25rem;
    overflow: hidden;
    box-shadow: 2px 2px 11px 0 rgba(0, 0, 0, .3)
}
.footer-container li {
    margin-bottom: .3125rem
}
.footer-container li a {
    cursor: pointer;
}

.footer-container .links .collapse {
    display: inherit
}
@media (max-width: 767px) {
    .block_newsletter {
        padding-bottom: .625rem;
        border-bottom: 1px solid #f6f6f6
    }
    .footer-container {
        box-shadow: none;
        margin-top: 0
    }
    .footer-container .wrapper {
        padding-left: 0!important;
        padding-right: 0!important
    }
    .footer-container .links .h3 {
        line-height: 1.5;
        font-size: 1rem
    }
    .footer-container .links .title {
        padding: 15px .625rem;
        border-bottom: 1px solid #f6f6f6;
        cursor: pointer
    }
    .footer-container .links .title .collapse-icons .remove,
    .footer-container .links .title[aria-expanded=true] .collapse-icons .add {
        display: none
    }
    .footer-container .links .title[aria-expanded=true] .collapse-icons .remove {
        display: block
    }
    .footer-container .links .navbar-toggler {
        display: inline-block;
        padding: 0
    }
    .footer-container .links .collapse {
        display: none
    }
    .footer-container .links .collapse.in {
        display: block
    }
    .footer-container .links ul {
        background-color: #f6f6f6;
        margin-bottom: 0
    }
    .footer-container .links ul>li {
        padding: .625rem;
        border-bottom: 1px solid #fff;
        font-weight: 700
    }
    .footer-container .links ul>li a {
        color: #232323
    }
}

.lang-de{
    #footer{
        .footer_list{
            [id *="link-cms-page-18"]{
                display: none;
            }
        }
    }
}