#cm{
    #c-bns{
        :first-child{
            border: 1px solid var(--cc-btn-primary-bg);
            &:hover{
                color: var(--cc-btn-primary-bg);
                border: 1px solid var(--cc-btn-primary-bg);
            }
        }
    }
}
#s-cnt{
    #s-bns{
        :first-child{
            border: 1px solid var(--cc-btn-primary-bg);
            &:hover{
                color: var(--cc-btn-primary-bg);
                border: 1px solid var(--cc-btn-primary-bg);
            }
        }
    }
    .b-ex{
        .title{
            border: 2px solid var(--cc-btn-primary-bg);
            border-radius: 5px;
        }
        .b-acc{
            border: 1px solid var(--cc-btn-primary-bg);
            border-radius: 5px;
        }
    }
}