.products-sort-order {
    .select-title {
        box-shadow: none;
        border: none;
        display: flex; 
        align-items: center;
        padding-left: 0;
        padding-right: 0;
    }
    .dropdown-menu {
        right: 0;
        left: auto;
        z-index: 999;
    }
    .select-list {
        &.current {
            background: $gray-2;
        }
    }
}

.facetes-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sort {
        display: inline-flex;
        align-items: center;
        font-size: font-size(5);
        @include media-up(md) {
            font-size: font-size(6);
        }
        .sort-by {
            padding-right: 5px;
            color: $gray-2;
            @include media-down(sm) {
                padding-left: 5px;
                padding-right: 5px;
            }
            @media (max-width: 360px) {
                display: none;
            }
        }
    }
}

.products-selection {
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    .showing {
        color: $gray-2;
        font-size: font-size(6);
        @media (max-width: 767px){
            padding-top: 0;
        }
    }
}
