html, body {
    font-size: $font-size-mobile;
    font-weight: font-weight(base);
    line-height: $line-height-base;
    letter-spacing: 0.4px;  // z wizualizacji
    @include media-up(lg) {
        font-size: $font-size-laptop;
        letter-spacing: 0.25px;
    }
    @include media-up(xxl) {
        font-size: $font-size-base;
        letter-spacing: 0.33px;
    }
}

body {
    color: $black;
    &:not(#cart):not(#checkout):not(#module-thecheckout-order) {
        padding-top: $navbar-height;
        @include media-down(lg) {
            padding-top: $navbar-height-mobile;
        }
    }
    @include media-down(lg) {
        &.search-open, &.blockcart-open {
            overflow-y: hidden;
        }
    }
}

.text-center {
    text-align: center;
}
.text-bold {
    font-weight: bold;
}


.label, label {
    font-size: inherit;
    text-align: left;
}

.home-section {
    margin-top: 6rem;
    margin-bottom: 6rem;
    @media (max-width: 991px) {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

.overlay-img {
    position: relative;
    &:before {
        content:'';
        background-color: $overlay-dark-1;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.separator {
    border-color: $gray-1;
}

.hidden-desktop {
    @media (min-width: 992px){
        display: none !important;
    }
}

.hidden-mobile {
    @media (max-width: 991px){
        display: none !important;
    }
}