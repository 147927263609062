
$white: #fff;
$gray-1: #F2F2F2;
$gray-2: #B8B8B8;
$gray-3: #777;
$gray-4: #444;
$black: #333;

$gray: $gray-1;

$primary: #BED9D6;
$secondary: #5B948E;
$tetriary: #c1b17e;

$pack-save-price: #88c17e;
$pack-header-txt: #5B948E;
$pack-header-border: #BED9D6;

$secondary-on-hover: #487470;

$overlay-dark: rgba(#000, 0.2);
$overlay-dark-1: rgba(#000, 0.1);

$line-height-base: 1.5;
$font-size-base: 16px;
$font-size-laptop: 14px;
$font-size-mobile: 13px;

$font-sizes: (
    1: 2.3125rem,
    2: 1.8125rem,
    3: 1.4375rem,
    4: 1.125rem,
    5: 1rem,
    6: 0.875rem,
    icon: 19px
);

$font-sizes-mobile: (
    1: 1.8125rem,
    2: 1.3846rem,
    3: 1.3846rem,
    4: 1.125rem,
    5: 1rem,
    6: 0.923rem,
);

$line-heights: (
    1: 1.73,
    2: 1.4, // 1.793 
    3: 1.5, // 1.87
    4: 2,
    5: 2.8, //2.143
    6: 2.364
);

$line-heights-mobile: (
    1: 1.3,
    // 2: 2.22,
    2: 1.5,
    3: 1.5,
    4: 2,
    5: 2.8,
    6: 2.364
);

$font-weights: (
    base: 500,
    semi-bold: 600,
    bold: 700,
);

$navbar-height: 206px;
$navbar-height-mobile: 84px;

$box-shadow: 0 0 10px 0 rgba(black, 0.05);


/* ======================== Custom CSS ========================*/
$main: $primary;
$dark: $black;
$light:$white;
$light-top:#767676;
$bg-footer: $white;
$font-icon: "FontAwesome";
$main_border:1px solid #ededed;