#module-thecheckout-order {
    #thecheckout-order-message {
        // .inner-area {
        //     padding: 0;
        // }
        label {
            margin-bottom: 5px;
        }   
        
/*         textarea {
            min-height: 80px;
            padding: 5px;
            @include media-down(md) {
                margin-left: 5px;
                margin-right: 5px;
            }
        } */
    }
}