.search_top {
    @include media-up(sm) {
        width: 50%;
    }

    &.is-active {
        transform: translateX(0);
        box-shadow: 0 0 15px 5px rgba(black, 0.1);
    }

    form {
        margin-top: 30px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        .text_input {
            background: transparent;
            font-size: font-size(5);
            padding: 5px 0;
            border-bottom: 2px solid $gray;
            margin-bottom: 30px;
            height: auto;
        }
    }

    .search-result-item {
        width: 50%;
        padding: 10px;

        @include media-up(sm) {
            width: 25%;
            padding: 10px;
        }

        img {
            margin-bottom: 5px;
        }

        .price {
            color: $black;
            font-weight: font-weight(semi-bold);
        }
    }

    .search_submit {
        width: 100%;
        padding: 20px 5px;
        background: $secondary;
        color: $white !important;
        display: none;

        &.is-active {
            display: block;
        }
    }

    #search_results {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 20px;
        max-height: calc(100% - 239px);
        overflow-y: auto;
        padding-left: 15px;
        padding-right: 15px;

        @include media-down(sm) {
            max-height: calc(100% - 227px);
        }
    }

    #search_loading {
        text-align: center;

        &:before {
            content: '';
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: rgba($black, 0.5);
            animation: pulse 1.5s infinite;
            display: none;

        }

        &.is-loading {
            &:before {
                display: inline-block;
            }
        }
    }

    #search_no_products {
        display: none;
    }
}

.search_widget_close {
    position: fixed;
    top: 15px;
    right: 15px;
    background-image: url(../img/icon-close.svg);
    width: 19px;
    height: 19px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.block-search {
    display: flex;
    align-items: center;
}

.search-icon {
    padding-right: 25px;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:before {
        content: '';
        display: block;
        background-image: url(../img/icon-search.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 19px;
        height: 19px;
    }
}