#module-thecheckout-order {
    #thecheckout-login-form {

        #login-form {
            padding: 10px;
            max-width: none;
        }

        .form-group {
            padding: 0;
        }

        .inner-area {
            .offer-login {
                text-align: inherit;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                a {
                    &.log-in {
                        font-size: inherit;
                        text-decoration: underline;
                    }
                }
                
            }
        }

        .already-account-label {
            flex-basis: 50%;
        }

        /* Password visibility icon */
        [data-link-action="toggle-password-visibility"] {
            top: 30px;
            right: 5px;
            margin-top: 0;
        }

        /* Login form */
        .form-footer {
            .btn-primary {
                display: block;
                width: 100%;
            }
        }
    }
}