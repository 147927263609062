#pagenotfound #main .page-header,
#products #main .page-header {
    margin: 2rem 0 3rem
}
#pagenotfound #main .page-content,
#products #main .page-content {
    margin-bottom: 10rem
}
#pagenotfound .page-not-found,
#products .page-not-found {
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, .2);
    background: #fff;
    padding: 1rem;
    font-size: .875rem;
    color: #7a7a7a;
    max-width: 570px;
    margin: 0 auto
}
#pagenotfound .page-not-found h4,
#products .page-not-found h4 {
    font-size: 1rem;
    font-weight: 700;
    margin: .5rem 0 1rem
}
#pagenotfound .page-not-found .search-widget,
#products .page-not-found .search-widget {
    float: none
}
#pagenotfound .page-not-found .search-widget input,
#products .page-not-found .search-widget input {
    width: 100%
}