
@mixin pos-transition($number) {
    -webkit-transition: all $number ease-in;
    -moz-transition: all $number ease-in;
    -ms-transition: all $number ease-in;
    -o-transition: all $number ease-in;
    transition: all $number ease-in;
  }
  
.block_newsletter {
    font-size: .875rem;
    margin-bottom: .625rem
}
.block_newsletter form {
    position: relative
}
.block_newsletter form input[type=text] {
    border: none;
    padding: 10px;
    min-width: 255px;
    color: #7a7a7a;
    border: 1px solid rgba(0, 0, 0, .25)
}
.block_newsletter form input[type=text]:focus {
    outline: 3px solid #333333;
    
    background: #fff
}
.block_newsletter form input[type=text]:focus+button .search {
    color: #333333
}
.block_newsletter form button[type=submit] {
    position: absolute;
    background: none;
    border: none;
    bottom: .3125rem;
    right: .125rem;
    color: #7a7a7a
}
.block_newsletter form button[type=submit] .search:hover {
    color: #333333
}
.block_newsletter p {
    padding-top: .625rem
}
.block_newsletter form .input-wrapper {
    overflow: hidden
}
.block_newsletter form input[type=text] {
    padding: 11px;
    width: 100%
}
.block_newsletter form input[type=text]:focus {
    border: 3px solid #333333;
    padding: 8px 8px 9px;
    outline: 0
}
.block_newsletter form input {
    height: 42px;
    box-shadow: none
}
#newsletterModal {
  @media (min-width: 992px) {
    .modal-dialog {
        max-width: 770px;
        margin: 30px auto;
    }
  }
  .row_newsletter {
    display: flex;
    flex-wrap: wrap;
  }
  
  .bg_newsletter-desktop {
    img {
      width: 300px;
    }
  }
  @media (max-width: 767px) {
    .bg_newsletter-mobile {
      background-image: url(../img/newsletter/bransoletka_coriss_mobile.jpg);
        background-size: cover;
        background-position: right bottom;
    }

  }
  // .bg_newsletter {
  //   background-image: url(../img/newsletter/bransoletka_coriss.jpg);
  //   background-size: cover;
  //   background-position: right bottom;
  // }
  .modal-title {
    position: relative;
    .title {
      padding-right: 30px;
      font-weight: 300;
      line-height: unset;
    }
  }
  .condition {
    font-size: 14px;
    line-height: unset;
    @media (min-width: 768px) {
      margin-top: 1rem;
    }
  }
  .close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background-image: url(../img/icon-close.svg);
    width: 15px;
    height: 15px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: .8;
  }
  form {
    @media (max-width: 767px) {
      .btn-container {
        text-align: center;
      }
    }
    button[type="submit"] {
      span {
        position: relative;
        &:before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          background-size: contain;
          background-position: center bottom;
          background-repeat: no-repeat;
          background-image: url(../img/serce_Coriss.svg);
          margin-right: 10px;
          margin-bottom: -3px;
        }
      }
    }
  }
}



/* module newletter */

.ft_newsletter {
    position: relative;
    $height-desktop: 58px;
    $height-mobile: 38px;
  
  
    h2 {
      margin: 0 0 10px 0;
      line-height: 1;
      color: #363f4d;
      position: relative;
      display: block;
      border: 0;
      font-size: 18px;
      font-weight: bold;
      // text-transform: capitalize;
      text-align: left;
      margin-top: 30px;
    }
  
    .desc {
      color: $light;
      font-size: 16px;
      font-weight: 300;
      // line-height: 1;
      display: block;
    }
  
    form {
      position: relative;
  
      .input_txt {
        display: inline-block;
        background-color: $gray-1;
        padding: 5px 15px;
        border: none;
        line-height: 30px;
        // color: $gray-2;
        // font-size: $font-size-base;
        width: 100%;
        // font-weight: 500;
        text-align: left;
        border-radius: 0px;
      }

      .btn {
        font-size: 14px;
        font-weight: 700;
        @include pos-transition(300ms);

  
        // &:hover {
        //   background: #eeeeee;
        //   color: #333;
        //   @include pos-transition(300ms);
        // }
  
      }

      .condition,
      .alert {
        margin: 10px 0 0;
        font-size: 12px;
        line-height: 24px;
        color: #bcbcbc;
        background: none;
        border: 0;
        padding: 0;
  
        &.alert-danger {
          color: $main;
        }
  
        &.alert-success {
          color: $main;
        }
      }

      &.form-default {
        height: $height-desktop;
        overflow: hidden;
        @media (max-width: 767px),
        (min-width: 992px) and (max-width: 1199px) {
          height: $height-mobile;
          margin-top: 5px;
        }
        .input_txt {
          padding-right: 205px; //button width + 15px space
          height: $height-desktop;
        }
        .btn {
          position: absolute;
          top: 0;
          right: 0;
          width: 190px;
          height: $height-desktop;
        }
        @media (max-width: 767px), (min-width: 992px) and (max-width: 1199px) {
          .input_txt {
          padding-right: 140px; //button width + 15px space  
          height: $height-mobile;
          padding-right: 140px; //button width + 15px space
          font-size: 12px;
          }
          .btn {
            width: 125px;
            height: $height-mobile;
            font-size: 11px;
            padding: 0 10px;
            
          }
        }
        .conditions {
          margin-top: 25px;
    
          @media (max-width: 991px) {
            margin-top: 15px;
          }
        }
      }
      &.form-modal {
        .input-wrapper {
          margin-top: 1rem;
          margin-bottom: 1rem;
          label {
            color: $gray-2;
            margin-bottom: 0;
            font-size: font-size(6);
          }
        }

        .custom-checkbox {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin-bottom: 0.5rem;
          span {
              -webkit-box-flex: 0;
              -ms-flex: 0 0 0.9375rem;
              flex: 0 0 0.9375rem
          }
          label {
              padding-left: .625rem;
          }
      }

      }
    }
  
    .block_content {
      p.newletter-header {
        margin-bottom: 10px !important;
        line-height: 24px;
      }
  
      .conditions {
        .condition {
          font-size: 13px;
          line-height: 18px;
  
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  /* end module newletter */


  body[id*='module-ps_emailsubscription'] {
    .card {
      box-shadow: none;
      border: none;
      text-align: center;
    }
  }

  .ft_newsletter {
    form.form-default {
      overflow: visible;
      height: auto;
    }
    #gdpr_consent {
      margin-top: 10px;
    }
    .conditions.messages {
      margin-bottom: 10px;
    }
  }