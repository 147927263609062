.header-checkout {
    padding: 0 !important;
    .custom-container-large {
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:after {
            display: none;
        }
        @include media-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            align-items: flex-start;
        }
    }

    .logo {
        width: 130px !important;
        @include media-down(sm) {
            width: auto !important;
            padding: 5px !important;
            max-width: 115px !important;
        }
    }

    &-heading {
        @include media-down(md) {
            font-size: font-size(5);
            line-height: 1;
            margin-bottom: 5px !important;
        }
    }
    &-subheading {
        font-size: font-size(6);
        color: $gray-3;
        @include media-down(md) {
            display: none;
        }
    }

    // &-email {
    //     @include media-down(sm) {
    //        display: none;
    //     }
    // }

    p {
        margin-bottom: 0;
    }

    a {
        color: $secondary;
        font-size: font-size(5);
    }

    .col {
        display: flex;
        align-items: center;

        &:nth-child(2) {
            padding-left: 10px;
        }

        @include media-down(md) {
            flex-direction: column;
            align-items: flex-end;
        }
    }
    .col-left, .col-right {
        padding: 0 10px;
        @include media-down(md) {
            text-align: right;
            padding: 0;
            min-width: 200px;
        }
    }


    .col-right {
        text-align: right;
        @include media-down(md) {

        }
    }
}