#module-thecheckout-order {
    #thecheckout-payment {
        .payment-option {
            margin-bottom: 15px;
            label {
                padding-top: 0;
            }
            @media only screen and (max-width: 390px) {
                    img[src*="payu_logo_small.png"], 
                    img[src*="payu_cards.png"] {
                        display: block;
                    }
            }
            // label {
            //     display: flex;
            //     flex-wrap: nowrap;
            //     align-items: center;
            //     justify-content: space-between;
            //     > span {
            //         margin-right: 15px;
            //     }
            // }
            // @include media-down(md) {
            //     padding-top: 5px;
            //     padding-bottom: 5px;
            // }
            
            
        }
    }
    
}

.payment-options {
    label {
        cursor: pointer;
    }
    .additional-information {
        font-size: font-size(6);
        a {
            text-decoration: underline;
        }
    }
}