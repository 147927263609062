/* To jest plik, w którym są zapisane CSS'y do tego dziwnego tworu, jakim jest produkt z wariantami, które są produktami */

.product-id-category-45 {
    .product-variants {
        .input-container {
            display: block;
            width: 100%;
            margin-bottom: 10px;
            label, label span {
                display: block;
                width: 100%;
                text-align: left !important;
            }
        }
    }
    .breadcrumb {
        display: none !important;
    }
}