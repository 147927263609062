#module-thecheckout-order {
    // font-size: font-size(6);
    // @include media-down(md) {
    //     font-size: font-size(5);
    // }
    #content-wrapper {
        @extend .custom-container-large;
        // @include media-up(md) {
        //     margin-top: 60px;
        // }
    }

    #content {
       padding: 0;
       padding-top: 30px;
       margin-left: -15px;
       margin-right: -15px;
    }
}

#module-thecheckout-order {

    .cart-overview {
        font-size: font-size(6);
        @include media-down(md) {
            font-size: font-size(5);
        }
        .product-attribute {
            color: $gray-2;
        }
        .product-image {
            img {
                max-height: 80px;
                width: auto;
            }
        }
    }

    label {
        font-size: font-size(6);
        @include media-down(md) {
            font-size: font-size(5);
        }
    }

    .delivery-option {
        border-bottom: 1px solid $gray-1;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .custom-radio input[type=radio]:checked + span {
        background-color: $secondary;
    }

    

    input.cart-line-product-quantity {
        border-color: rgba($gray-1, 0.5);
    }

    a.cart-line-product-quantity-up,
    a.cart-line-product-quantity-down {
        border-color: $gray-1;
        opacity: 0.5;
    }

    /* Fields */
    .second-address,
    .business-customer {
        padding-left: 5px;
        margin-top: 15px;
    }

    .shipping-address-notice {
        padding-left: 5px;
        margin-bottom: 10px;
    }

    .block-promo .promo-code {
        padding: 0;
    }

    .shipping-radio {
        /* Center vertical */
        display: flex;
        align-items: center;
    }

    .delivery-option-label {
        margin-bottom: 0;
    }

    .delivery-option-list,
    .payment-options {
        padding-left: 5px;
    }

    .shopping-cart-header {
        box-sizing: none !important;
    }
    
    .breadcrumb_container {
        @include media-down(md) {
            margin-bottom: 20px;
        }
    }

    .qty-label.label-success {
        background: transparent;
        // color: $success;
    }

    .has-float-label{
    
        :not(:placeholder-shown):not(:focus) {
            border-color: $secondary;
        }
    }
    .business-fields-separator {
        &:after {
            width: 100%;
            margin: 20px 0;
        }
    }

    .cart-summary-line {
        > span {
            font-size: font-size(6);
            @include media-down(md) {
                font-size: font-size(5);
            }
        }
    }
    .delete-from-cart {
        opacity: 0.2;
        &:hover {
            opacity: 1;
        }
    }
    .block-promo {
        padding-top: 15px;
        margin-top: 15px;
        border-top: 1px solid $gray-1;
        .promo-code {
            background: none;
            form {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
            }
        }
        .promo-highlighted{
            padding-left: 0;
            font-weight: 600;
        }
        .promo-discounts{
            margin-top: 1rem;
            span{
                color: $black;
                font-size: 0.925rem;
            }
            .code{
                color: $secondary;
                cursor: pointer;
            }
        }
        .promo-code-button {
            color: $gray-3;
            font-size: font-size(6);
            @include media-down(md) {
                font-size: font-size(5);
            }
        }
    }


    .custom-checkbox {
        a {
            text-decoration: underline;
        }
    }

}

body {
    &.compact-cart {
        #tc-container {
            .qty-container {
                .qty-box {
                    transform: scale(1);
                }
            }

            .qty-label {
                &.label-success {
                    background-color: transparent;
                    // color: $success;
                    padding: 0;
                    margin-bottom: 5px;
                    display: inline-block;
                }
            }

            .cart-item {

                .product-line-info {
                    &:first-child {
                        padding-right: 25px;
                    }

                    
                }
            }

            .cart-detailed-totals {
                padding: 10px;
            }

            .cart-grid.row {
                margin-bottom: 0;
            }


            .product-line-image {
                img {
                    max-width: 50px;
                }
            }
        }
        #tc-container, #tc-container-mobile {
            .product-line-delete {
                padding: 0;
                position: absolute;
                right: 0;
                top: 0;
            }
            .cart-item {
                .product-line {
                    position: relative;
                }
            }
            .product-line-actions {
                .product-line-qty {
                    text-align: left;
                    padding-left: 0;
                }

                @include media-down(md) {
                    > div {
                        text-align: left;
                    }
                }
                
            }
        }
        #tc-container-mobile {
            .product-line-delete {
                top: -20px;
            }
        }


        

        .cart-item {
            &:first-child {
                padding-top: 0;
            }
        }
    }

    .delivery-options-list {
        padding-left: 5px;
    }

    .form-control {
        border-color: $gray-2;
        
    }
}

#thecheckout-order-message,
#thecheckout-newsletter,
#thecheckout-required-checkbox-1,
#thecheckout-required-checkbox-2,
#thecheckout-required-checkbox-3,
#thecheckout-confirm,
#thecheckout-psgdpr {
    &.checkout-block {
        margin-top: 0;
        margin-bottom: 0;
        box-shadow: none;
        border: none;
    }

    .card-body {
        padding-top: 5px;
        padding-bottom: 0;
    }
    .inner-area {
        padding: 5px 10px;
    }
}


#thecheckout-confirm {
    &.checkout-block {
        margin-left: 0;
        margin-right: 0;
    }
}

#confirm_order {
    display: block;
    width: 100%;
    @include media-down(xs) {
        font-size: 15px;
    }
}

/* Loader */
.lds-ellipsis-m div > div {
    top: 125px;
}

.page-content .form-control, .page-content select.form-control-select.form-control {
    font-size: font-size(6);
    @include media-down(md) {
        font-size: font-size(5);
    }
}
