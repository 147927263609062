#product {

    .product-prices {
        margin: 0 0 15px 0;
        display: flex;
        align-items: center;
        letter-spacing: 1px;

        @include media-up(lg) {
            margin-bottom: 30px;
        }

        div {
            margin: 0;
        }

        .product-price {
            font-weight: font-weight(bold);
        }

        .current-price {
            font-weight: font-weight(bold);
            font-size: font-size(3);
            @include media-up(md) {
                font-size: font-size(4);
            }
        }

        .has-discount.product-price,
        .has-discount p {
            color: $black;
            font-weight: font-weight(base);
        }

        .product-discount {
            .regular-price {
                font-weight: font-weight(bold);
                font-size: font-size(4);
                color: $gray-2;
            }
        }

        .has-discount {
            .discount {
                background: $tetriary;
                padding: 0 15px;
                font-size: font-size(6);
            }
        }
    }

    .product-discounts {
        margin: 0;
    }

    .product-pack-price {
        margin: 0;
        color: $gray-2;
        margin-left: 10px;
      }
    
    .product-pack-diff {
        background: $pack-save-price;
        padding: 0 15px;
        font-size: font-size(6);
        display: inline-block;
        color: $white;
        margin-left: .625rem; 
        font-weight: font-weight(semi-bold);
    }

    .product-pack-head{
        border: 1px solid;
        border-color:$pack-header-border;
        font-size: font-size(6);
        padding: 0 15px;
        color: $pack-header-txt;
        display: inline-block; 
        font-weight: font-weight(semi-bold);
        text-transform: uppercase;
        
    }

    .pack-disc-price{
        font-weight: bold;
        font-size: font-size(4);
        color: $black;
        padding: 30px;
    }

    .pack-disc-rabat{
        background-color:$tetriary;
        font-size: font-size(6);
        padding: 0 15px;
        color: $white;
        display: inline-block; 
        font-weight: font-weight(semi-bold);
    }

}
