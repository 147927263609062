.popup_cart {

    @include media-up(sm) {
        width: 450px;
    }

    &.is-active {
        box-shadow: 0 0 15px 5px rgba(black, 0.1);
        transform: translateX(0);
    }

    ul {
        max-height: calc(100% - 320px);
        overflow-y: auto;
        padding-left: 20px;
        padding-right: 20px;
        li {
            padding-bottom: 30px;
            display: flex;
            position: relative;
            margin-bottom: 30px;
            &:not(:last-child) {
                border-bottom: 1px solid $gray-1;
            }
        }
    }

    .img_content {
        width: 35%;
        position: relative;
        img {
            width: 100%;
        }
    }

    .right_block {
        width: 65%;
        padding-left: 15px;
        display: flex;
    }

    .product-info {
        width: 70%;
    }

    .product-price {
        width: 30%;
        text-align: right;
        display: flex;
        align-items: flex-end;
        position: relative;
        font-size: font-size(4);
    }

    .price-info {
        display: block;
        color: $gray-2;
        font-size: font-size(6);
        font-weight: font-weight(base);
        margin-top: 5px;
    }


    .product-name {
        line-height: 1.4;
        margin-bottom: 20px;
    }
    .attributes_content {
        color: $gray-2;
        span {
            display: inline-block;
            margin-bottom: 10px;
        }
    }

    .product-quantity {
        color: $gray-2;
        margin-bottom: 10px;
    }
    .remove_button {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.2;
        .icon {
            $size: 15px;
            width: $size;
            height: $size;
            background-image: url(../img/icon-delete.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            display: block;
        }
    }

    .checkout {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 100%;
        background: $gray;
        padding: 15px;
        z-index: 2;
        a {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        .cart-total {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .label, .value {
                width: 50%;
                font-weight: font-weight(bold);
            }
            .label {
                padding-right: 15px;
            }
            .value {
                padding-left: 15px;
                color: $secondary;
                text-align: right;
                font-size: font-size(4);
            }
        }
    }
    .no-products {
        text-align: center;
    }
}

.button_cart {
    a {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .icon {
        $size: 19px;
        width: $size;
        height: $size;
        background-image: url(../img/icon-cart.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        position: relative;
    }
    .item_count {
        $size: 14px;
        position: absolute;
        right: calc(-#{$size} / 2);
        top: calc(-#{$size} / 2);
        height: $size;
        width: $size;
        color: $white;
        border-radius: 50%;
        text-align: center;
        line-height: $size;
        font-size: 11px;
    }
}

.blockcart, .button_cart {
    height: 100%;
}

#_desktop_cart_block {
    display: flex;
    align-items: center;
}