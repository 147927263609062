.page-my-account #content .links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}
.page-my-account #content .links a {
    text-align: center;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #7a7a7a;
    padding: 0 .9375rem;
    margin-bottom: 1.875rem
}
.page-my-account #content .links a span.link-item {
    display: block;
    height: 100%;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, .2);
    background: #fff;
    padding: 1rem
}
.page-my-account #content .links a i {
    display: block;
    font-size: 2.6rem;
    width: 100%;
    /* color: #232323; */
    padding-bottom: 3.4rem
}
.page-my-account #content .links a:hover {
    color: #232323
}
.page-my-account #content .links a:hover i {
    color: #333333
}