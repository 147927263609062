.images-container {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    @include media-up(md) {
        margin-top: 0;
        margin-bottom: 0;
    }

    img {
        cursor: pointer;
    }

    .gallery-thumbnails {
        /* hide on mobile, where swipe is more intuitive than clicking thumbnails */
        display: none;
        flex-direction: column;
        align-items: flex-start;
        max-width: 120px;
        margin-right: 30px;
        @include media-up(md) {
            display: flex;
            width: 20%;
        }
        
        img {
            max-width: 100%;
        }


       .slick-up{
            border: none;
            background: none;
            transform: rotate(90deg);
            margin: 0 auto;
        }

        .material-icons {
            font-size: 40px;
            opacity: 0.4;
            padding: 0;
            &:hover{
                opacity: 0.8;
            }
        }
      
    }

    /* Single thumnbail wrapper (not image element) */
    .gallery-thumbnails-item {
        border: 1px solid $gray-1;
        overflow: hidden;
        margin-bottom: 15px;
        width: 120px !important;
        transition: border-color 0.2s ease-in-out;
        


        img {
            opacity: 0.7;
            transition: opacity 0.2s ease-in-out;
        }

        &:hover {
            img {
                opacity: 1;
            }
        }

        &.is-active {
            border-color: $secondary;
            img{
                opacity: 1.2;
            }
        }
    }

    /* Wrapper for main images slider */
    .gallery-image {
        width: 100%;
        border: 1px solid $gray-1;

        @include media-up(md) {
            width: 80%;
        }

        &.slick-slider {
            position: relative;
            
            @include media-down(md) {
                margin-bottom: 30px;
            }
            .slick-arrow {
                position: absolute;
                top: 0;
                height: 100%;
                width: 50px;
                z-index: 100;
                background: none;
                border: none;
                cursor: pointer;

                /* Temporary disabled */
                display: none !important;

                .material-icons {
                    font-size: 50px;
                    color: $black;
                    opacity: 0.2;
                    transition: opacity 0.2s;
                    text-align: left;
                    width: auto;
                    height: auto;
                    display: inline;

                }
                &:hover {
                    .material-icons {
                        opacity: 0.5;
                    }
                }
               

            }
            .slick-prev {
                left: 0;
            }
            .slick-next {
                right: 0;
            }
           
        }
    }

     
}