.product-accessories{
    .pos_title{
        margin-bottom: 0;
    }
    .swiper-related-products{
        width: 100%;
        overflow: hidden;
    }
    .swiper-arrows{
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 1rem;
        div{
            color: $secondary;
            margin: 10px 0px;
            position: inherit;
            &:hover{
                color: $secondary-on-hover;
            }
            &::after{
                display: none;
            }
            i.fa{
                font-size: 40px;
            }
        }
        .swiper-button-disabled{
            color: $secondary;
        }
    }
}
