/* style layer cart */
#blockcart-modal {
    max-height: 100vh;
    overflow-y: auto;
    .modal-body {
        background: $light;
        padding: 15px 30px;
        @include media-down(xs) {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .modal-content {
        @include media-down(xs) {
            height: 95%;
            height: calc(100% - 20px);
            display: flex;
            flex-direction: column;
        }
    }

    .modal-dialog {
        max-width: 800px;
    }

    .modal-header {
        @extend .custom-modal-title;
        // background-color: ;
        .modal-title {
            padding: 0 20px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 1;
        }

        [data-dismiss="modal"] {
            @extend .custom-modal-close;
        }
    }
    .close {
        width: 2rem;
        height: 2rem;
        max-height: 100%;
        padding: .5rem;
        opacity: 1;
        color: #333;
        z-index: 99;
    }

    .product-image {
        max-height: 150px;
        @include media-down(xs) {
            text-align: center;
            margin: 0 auto;
            display: block;
        }
    }

    .product-name {
        line-height: 1.4;
        @include media-down(xs) {
            text-align: center;
        }
    }
    .btn-primary {
        margin-bottom: .5rem;
        @include media-down(xs) {
            display: block;
            width: 100%;
        }

    }
    .product-cart-details, .cart-total {
        font-size: font-size(6);
        @include media-down(xs) {
            padding-top: 15px;
            padding-bottom: 10px;
            border-top: 1px solid $gray-1;
            font-size: font-size(5);
        }
        // @include media-down(md) {
        //     font-size: font-size(4);
        // }
    }
    .product-cart-details {
        p {
            margin-bottom: 5px;
        }
    }

    .dismiss-modal {
        @extend .btn-muted-link;

        @include media-down(xs) {
            display: block;
            width: 100%;
        }
    }
}

/* end style layer cart */