.category-cover {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: $gray-1;
    .block-category-wrapper {
        padding: 30px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        .block-category {
            width: 600px;
            max-width: 100%;
            .category-counter {
                font-size: font-size(5);
            }
            #category-description {
                font-size: font-size(6);
            }
        }
    }
    &.category-cover-img {
        position: relative;
        background-size: cover;
        background-position: center;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $overlay-dark;
        }
        .block-category-wrapper {
            position: relative;
        }
    }
}

#category{
    .products{
        justify-content: center;
        @include media-up(lg){
            padding: 0 10px;
        }
        .item-product{
            margin-left: 1rem;
            margin-right: 1rem;
            margin-bottom: 3rem;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
            padding-bottom: 10px;
            padding-top: 10px;
            @include media-up(sm){
                width: 45%;
            }
            @include media-up(md){
                width: 29.8%;
            }
            @include media-up(lg){
                width: calc(100% / 3);
                box-shadow: none;
                margin-left: 0;
                margin-right: 0;
                &:hover{
                    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
                    .product-actions{
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                    } 
                }
            }
            @include media-up(xl){
                width: 25%;
            }
            .js-product-miniature{
                height: 100%;
                display: grid;
            }
            .product-actions{
                display: flex;
                justify-content: center;
                bottom: 0;
                left: 0;
                right: 0;
                align-items: end;
                @include media-up(lg){
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
                    transform: translateY(100%);
                }
                .add-to-cart{
                    display: flex;
                    align-items: center;
                    height: 100%;
                }
                .icon {
                    background: url(../img/icon-add-to-cart.svg);
                    width: 1.5em;
                    height: 1.5em;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: inline-block;
                    margin-right: 1em;
                }
            }
        }
    }
}
