#product {
    .product-variants {

        label {
            margin: 0;
        }

        .color,
        .custom-checkbox input[type=checkbox]+span.color {
            margin: 0;
        }

        .color {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            margin-right: 10px;
        }
        .input-radio,  .input-color {
            + span {
                transition: background-color 0.2s, border-color 0.2s;
            }
        }

        .attribute-group-color {
            display: flex;
            align-items: center;

            .input-color:checked+span,
            .input-color:hover+span {
                border: 1px solid $gray-2;
            }
        }

        .attribute-group-radio {
            .radio-label {
                padding-top: 10px;
                padding-bottom: 10px;
                min-width: 160px;
                text-align: center;
                font-weight: font-weight(bold);
                letter-spacing: 1px;
                border: 1px solid $gray-2;
                box-shadow: none;
            }

            .input-radio:checked + span, .input-radio:checked:hover + span {
                border: 1px solid $secondary;
                background: $secondary;
                color: $white;
            }
            .input-radio:hover + span {
                background: $gray-1;
            }
        }

        > .product-variants-item {
            margin: 0;
            padding-bottom: 15px;
            
            @include media-up(lg) {
                margin-bottom: 30px;
            }

            .control-label {
                margin-bottom: 5px;
            }
            

            &:not(:last-child) {
                border-bottom: 1px solid $gray-1;
                margin-bottom: 15px;
            }
        }

        .form-control {
            font-size: font-size(6);
        }

        .input-container {
            margin-bottom: 10px;
        }    
    }
}