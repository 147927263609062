.block-social {
    text-align: right
}
.block-social li {
    height: 2.5rem;
    width: 2.5rem;
    background-color: #f1f1f1;
    background-repeat: no-repeat;
    display: inline-block;
    margin: .125rem;
    cursor: pointer
}
.block-social li:hover {
    background-color: #333333
}
.block-social li a {
    display: block;
    height: 100%;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden
}
.block-social li a:hover {
    color: transparent
}
@media (max-width: 991px) {
    .block-social {
        text-align: center
    }
    .block-contact {
        padding-left: 1.5rem
    }
}