#order-confirmation {
    #content {
        margin-top: 2rem;

        .order-confirmation-table {
            border: 1px solid $gray-1;
            margin-top: 15px;
            margin-bottom: 15px;
            padding: 0 15px 15px 15px;
            .order-line {
                padding: 1rem;
                &:nth-child(2n+1) {
                    background-color: $gray-1;
                }
                @media (max-width: 575px) {
                    .image img, .details {
                        margin-bottom: 15px;
                    }
                }
            }
        }
        #order-details {
            margin-bottom: 2rem;
            li {
                margin-bottom: 0.25rem;
            }
        }
        
    }
    #content-hook_payment_return {
        margin-top: 2rem;
        margin-bottom: 2rem;
        dl {
            margin-bottom: 2rem;
        }
    }
}



// Wzięte z theme.css:

#order-items hr {
    border-top-color: $gray-1;
}
#order-items table {
    width: 100%
}
#order-items table tr {
    height: 1.875rem
}
#order-items table tr td:last-child {
    text-align: right
}
#order-items .details .customizations {
    margin-top: .625rem
}
#order-confirmation #registration-form {
    width: 50%;
    margin: 0 auto 1rem
}

@media (max-width: 767px) {
    // #order-items {
    //     border-right: 0;
    //     margin-bottom: 2.5rem
    // }
    // #order-items .card-title {
    //     border-bottom: 1px solid #f1f1f1;
    //     margin-bottom: 1rem;
    //     padding-bottom: 1rem
    // }
    // #order-items hr {
    //     border-top-color: #f1f1f1
    // }
    // #order-details {
    //     padding-left: .9375rem
    // }
    // #order-details .card-title {
    //     border-bottom: 1px solid #f1f1f1;
    //     margin-bottom: 1rem;
    //     padding-bottom: 1rem
    // }
}

