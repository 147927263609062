#cart, #module-thecheckout-order {
    .product-discount {
        line-height: 1;
    }

    .has-discount {
        .discount {
            background: $tetriary;
            padding: 0 15px;
            font-size: font-size(6);
            line-height: 2;
        }
        .current-price {
            line-height: 1.4;
            font-size: font-size(4);
            color: $secondary;
        }
    }
}