/* module social */
// #header {
//     .social_follow {
//       float: right;
//       width: auto;
//       padding: 0;
  
//       li {
//         display: inline-block;
//         background-image: none !important;
//         padding: 0 10px;
//         margin: 0;
  
//         &:last-child {
//           padding-right: 0;
//         }
  
//         a {
//           position: relative;
//           display: inline-block;
//           vertical-align: middle;
//           color: $light;
//           background: transparent;
//           font-size: 0;
//           line-height: 13px;
//           width: auto;
//           height: auto;
//           padding: 0;
//           z-index: 1;
//           border: 0;
//           @include pos-transition(300ms);
  
//           &:before {
//             padding: 0 !important;
//             font-size: 12px;
//             display: inline-block;
//             font-family: $font-icon;
//             font-weight: 500;
//             vertical-align: middle;
//             -webkit-font-smoothing: antialiased;
//             -moz-osx-font-smoothing: grayscale;
//           }
  
//           &:hover {
//             color: $main;
//             @include pos-transition(300ms);
//           }
  
//         }
  
//         &.facebook {
//           a {
  
//             &:before {
//               content: "\f09a";
//             }
//           }
//         }
  
//         &.twitter {
//           a {
//             &:before {
//               content: "\f099";
//             }
//           }
//         }
  
//         &.rss {
//           a {
//             &:before {
//               content: "\f09e";
//             }
//           }
//         }
  
//         &.youtube {
//           a {
//             &:before {
//               content: "\f167";
//             }
//           }
//         }
  
//         &.googleplus {
//           a {
//             &:before {
//               content: "\f0d5";
//             }
//           }
//         }
  
//         &.pinterest {
//           a {
//             &:before {
//               content: "\f0d2";
//             }
//           }
//         }
  
//         &.vimeo {
//           a {
//             &:before {
//               content: "\f194";
//             }
//           }
//         }
  
//         &.instagram {
//           a {
//             &:before {
//               content: "\f16d";
//             }
//           }
//         }
//       }
//     }
  
//     h4,
//     .payment {
//       display: none;
//     }
//   }
  
  .social_follow {
    position: relative;
    #footer & {
      margin: 0;
      margin-bottom: 20px;
      li {
        margin: 0;
        margin-right: 15px;
      }
    }
    #header & {
        margin-right: 1rem;
    }
    // #header & {
    //   display: none;
    // }

    li {
      display: inline-block;
      margin: 0 10px 0 0;
  
      @media (max-width: 991px) {
        margin: 0 0 0 12px;
      }
  
      line-height: 32px;
      background-image: none !important;
      padding: 0;
  
      a {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        color: #b2b2b1;
        font-size: 0;
        padding: 0;
        line-height: 30px;
        border: 0;
        z-index: 1;
        @include pos-transition(300ms);
  
        span {}
  
        &:before {
          padding: 0 !important;
          font-size: 22px;
          display: inline-block;
          font-family: $font-icon;
          font-weight: 500;
          vertical-align: middle;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
  
        &:hover {
          border-color: $main;
          color: $main;
          ;
          @include pos-transition(300ms);
        }
  
      }
  
      &.facebook {
        a {
  
          &:before {
            // content: "\f09a";
            content: url('../img/facebook.svg');
          }
        }
      }
  
      &.twitter {
        a {
          &:before {
            content: "\f099";
          }
        }
      }
  
      &.rss {
        a {
          &:before {
            content: "\f09e";
          }
        }
      }
  
      &.youtube {
        a {
          &:before {
            content: "\f167";
          }
        }
      }
  
      &.googleplus {
        a {
          &:before {
            content: "\f0d5";
          }
        }
      }
  
      &.pinterest {
        a {
          &:before {
            content: "\f0d2";
          }
        }
      }
  
      &.vimeo {
        a {
          &:before {
            content: "\f194";
          }
        }
      }
  
      &.instagram {
        a {
          &:before {
            // content: "\f16d";
            content: url('../img/instagram.svg');
          }
        }
      }
    }
  }
  
  /* end module social */