#module-thecheckout-order {
    .opc-header, .opc-header-mobile {
        &__title {
            margin-bottom: 0.5em;
            letter-spacing: 1px;
            line-height: 1;
            font-size: font-size(3);
            font-weight: normal;
        }
    
        &__desc {
            margin-bottom: 0;
            color: $gray-2;
        }
    
        &__item {
            padding-bottom: 30px;
            text-align: center;
        }
    }
    
    .opc-header {
        
        @include media-down(md) {
            display: none;
        }
    }
    
    .opc-header-mobile {
        text-align: center;
        margin-bottom: 30px;
        &__title {
            font-size: font-size(1);
        }
        @include media-up(lg) {
            display: none;
        }
    }
}
