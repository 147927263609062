#module-thecheckout-order {
    #thecheckout-shipping {
        #gift {
            margin-top: 15px;
            margin-bottom: 15px;
            display: none !important; // ukrycie pola na wiadomość do pakowania ozdobnego
            label {
                margin-bottom: 5px;
            }
        }
        [for=input_gift] {
            position: relative;
            
            span {
                color: $secondary;
                text-decoration: underline;
                white-space: nowrap;
            }
        }

        .gift-preview {
            opacity: 0;
            position: absolute;
            bottom: 100%;
            margin-bottom: 2em;
            left: 0;
            width: 100%;
            transition: opacity 0.25s ease;
            pointer-events: none;
            border: 1px solid $gray-1;
            box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
            z-index: 10;
            max-width: 500px;
            &.is-active {
                opacity: 1;
                pointer-events: all;
            }

            &.display-bottom {
                margin-bottom: 0;
                bottom: auto;
                margin-top: 2em;
                top: 100%;
            }
        }


        .delivery-options {
            margin-bottom: 15px;
        }
        .delivery-option-logo {
            width: 85px;
        }

        .delivery-option-delay {
            color: $gray-2;
        }
    
        .delivery-option-name {
            .carrier-name {
                line-height: 1;
                font-weight: font-weight(semi-bold);
            }
        }

        .block-header{
            margin-bottom: 0px;
        }

        .delivery-message{
            color: #b8b8b8;
            margin-bottom: 20px;
        }
    }
}