.pagination {
    width: 100%;
    padding: 5px 15px;
    @media (min-width: 992px){
      padding: 10px 15px;
    }
    background-color: $gray-1;
}
// .pagination>div:first-child {
//     line-height: 2.5rem
// }
// .pagination .page-list {
//     background: #fff;
//     padding: .375rem;
//     margin-bottom: 0;
//     box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .2)
// }
.pagination .page-list li {
    display: inline
}
// .pagination a {
//     color: #000;
//     font-weight: 600
// }
// .pagination a:not(.previous):not(.next) {
//     letter-spacing: .125rem
// }
// .pagination .previous {
//     float: left
// }
// .pagination .next {
//     float: right
// }
// .pagination .disabled {
//     color: #7a7a7a
// }
.pagination .current a {
    color: $gray-2;
    pointer-events: none;
}

.category-cover {
    .page-list {
        background: #f4f4f4 !important;
        box-shadow: none !important;
      }
    
      .pagination { //
        font-size: $font-size-base;
        font-weight: 500;
        margin: 0;
        border-bottom: $main_border;
        padding-bottom: 0px;
        background: #f4f4f4;
        margin: 30px 0;
    
        a {
          font-weight: 500;
          color: $dark;
          padding: 0 5px;
          font-size: $font-size-base;
    
          i {
            font-size: 18px;
          }
    
          &:hover {
            color: $main;
          }
        }
    
        .disabled {
          color: $dark;
        }
    
        .current a {
          color: $main;
          font-size: $font-size-base;
        }
      }
}

