.custom-modal {
    $size: 100%;
    position: fixed;
    top: 0;
    right: 0;
    width: $size;
    height: 100vh;
    max-height: 100vh;
    background: $white;
    z-index: 10001;
    // overflow-y: hidden;
    transform: translateX(100%);
    transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), top 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);

    &-title {
        font-size: font-size(3);
        text-transform: uppercase;
        text-align: center;
        padding: 15px;
        padding-bottom: 15px;
        margin-bottom: 20px;
        border-bottom: 1px solid $gray-1;
        letter-spacing: 1px;
        @include media-up(lg) {
            font-size: font-size(4);
            margin-bottom: 30px;
        }
    }
    
    &-close {
        position: fixed;
        top: 15px;
        right: 15px;
        background-image: url(../img/icon-close.svg);
        width: 19px;
        height: 19px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    &-overlay {
        width: 100%;
        height: 100%;
        background: rgba($black, 0.5);
        top: 0;
        left: 0;
        z-index: 10000;
        position: fixed;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        &.is-active {
            opacity: 1;
            visibility: visible; 
        }
    }
}