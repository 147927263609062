@keyframes pulse {
	0% {
        transform: scale(0);
        opacity: 1;
	}

	100% {
        transform: scale(1);
        opacity: 0;
	}
}