.js-product-miniature {
    /* Catalog */
    .product_desc {
        .product_name {
            font-size: font-size(5);
            &:hover {
                color: $secondary;
            }
        }
    }

    &:hover {
        .product_desc {
            .product_name {
                color: $secondary;
            }
        }
    }
    

    .product-price-and-shipping {
        .price, .regular-price {
            font-size: font-size(5);
            font-weight: 600;
        }
        .historical-price{
            padding-top: 0.5rem;
            font-size: 0.75rem;
        }
    }

    /* Featured products */
    .add-to-links {
        display: none !important;
    }
    .quick_view {
        display: none !important;
    }
    
    /* Home tab category slider */
    .tab-category-container-slider & {
        .product_desc {
            .product_name {
                font-size: 16px;
            }
        }
        .product-price-and-shipping {
            .price, .regular-price {
                font-size: 16px;
            }
        }
    }

    .on-sale, .product-flag .discount-percentage {
        left: 4px;
    }

}