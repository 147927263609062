.page-addresses .address {
    margin-bottom: 1.875rem;
    font-size: .875rem;
    color: #232323;
    border: 1px solid $gray-2;
}
.page-addresses .address .address-body {
    padding: 1rem
}
.page-addresses .address .address-body h4 {
    font-size: 1rem;
    font-weight: 700
}
.page-addresses .address .address-body address {
    min-height: 9rem
}
.page-addresses .address .address-footer {
    border-top: 1px solid $gray-2;
    padding: .5rem 1rem
}
.page-addresses .address .address-footer a {
    color: $gray-2;
    margin-right: .5rem
}
.page-addresses .address .address-footer a:hover {
    color: #232323
}
.page-addresses .address .address-footer a i {
    font-size: 1rem
}
.page-addresses .address .address-footer a span {
    font-size: .9375rem;
    vertical-align: middle
}
.page-addresses .addresses-footer {
    margin: 0 .9375rem
}
.page-addresses .addresses-footer a,
.page-addresses .addresses-footer a:hover {
    color: #232323
}
.page-addresses .addresses-footer a i {
    font-size: 1rem
}
.page-addresses .addresses-footer a span {
    font-size: 1rem;
    vertical-align: middle;
    margin-top: .625rem
}