.page-customer-account {
    .page-header {
        text-align: center;
        margin-top: 1.5rem;
    }
    #content {
        background: $white;
        font-size: .875rem;
        margin: 2rem auto;
        box-shadow: none;
        border: none;
    
        form {
            margin: 2rem 0;
            .send-renew-password-link {
                margin-bottom: 2rem;
            }
            .form-footer {
                text-align: center;
            }
        }
     
        .order-actions {
            a {
                padding: 0 .125rem
            }
        }
        .forgot-password {
            text-align: center;
            font-size: .875rem;
            margin-top: 1rem;
            padding-bottom: .9375rem
        }
        .no-account {
            text-align: center;
            font-size: 1rem;
        }
    }
}