#index .featured-products {
    @extend .custom-container-full;
    @extend .home-section;
    
    @include media-down(md) {
        margin-top: 30px;
    }
    
    .products-section-title {
        display: none;
    }
    .js-product-miniature {
        margin-bottom: 30px;
    }
    .all-product-link {
        display: none;
    }
}