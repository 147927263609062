.tab-category-container-slider {
    @extend .home-section;
    h2 {
        margin-bottom: 35px;
        @include media-down(sm) {
            font-size: 24px;
            margin-bottom: 0;
        }
    }
	.tab_cates {
		@media(max-width: 767px){
			margin-top:30px;
			li {
				margin-bottom: 10px;
			}
        }
        @include media-down(xs) {
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
        }
		li {
			position:relative;
			display:inline-block;
			line-height:18px;
			padding:0 25px;
			text-transform:uppercase;
			cursor:pointer;
			color:#9b9b9b;
            letter-spacing:0;
            padding-bottom: 10px;
			font: {
				size:13px;
				weight:500;
			}
			&.active {
                font-weight: 600;
                color: $black;
                border-bottom: 1px solid $primary;
            }
            @include media-down(xs) {
                padding-left: 5px;
                padding-right: 5px;
                flex-basis: 50%;
            }
			
		}

    }
    .item-product {
        padding: 10px;
    }
} 

