#product {
    /* Long description section */
    .product-description-block {
        // margin-top: 25px;
        margin-bottom: 25px;

        @include media-up(xl) {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        /* Section heading */
        .product-description-block-title {
            font-size: font-size(2);
            display: block;
            padding: 15px 0;
            border-top: 1px solid $gray-1;

            @include media-up(xl) {
                padding: 35px 0;
            }
            
        }

        /* Text block in description section */
        .product-description {
            width: 100%;

            /* Make nice offset on large screens */
            @include media-up(lg) {
                width: 80%;
            }

            @include media-down(lg) {
                margin-top: 0;
                margin-bottom: 15px; 
            }

            /* Reformat texts from DB (only simple HTML tags) */
            p {
                margin-bottom: 0;

                strong,
                b {
                    display: inline-block;
                    margin-top: 10px;
                    margin-bottom: 5px;
                }
                &:first-child {
                    strong, b {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}