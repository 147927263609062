#module-thecheckout-order {
    padding-top: 0 !important;
    #header {
        position: static;
    }
}
#header {
    color: $black;
    background: $white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: $box-shadow;
    transform: translateY(0);
    transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);

    #index & {
        box-shadow: none;
    }

    .logo {
        width: 120px;
        transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        @include media-up(xl) {
            width: 140px;
        }
        @include media-up(xxl) {
            width: 175px;
        }
        > a {
            display: block;
        }
    }

    .static-nav {
        padding-top: 5px;
    }

    .header-top {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray-1;
        @extend .custom-container-full;
        .displayheader{
            display: grid;
            grid-template-areas:"contact social . currency lang user";
            grid-template-columns: auto auto 1fr;
            align-items: center;
            #_desktop_user_info{
                grid-area: user;
            }
            #_desktop_language_selector{
                grid-area: lang;
            }
            #_desktop_currency_selector{
                grid-area: currency;
            }
            #_desktop_static{
                grid-area: contact;
            }
            .social_follow{
                grid-area: social;
                margin-left: 1rem;
            }
        }
    }

    .header-bottom {
        @extend .custom-container-full;
        font-size: font-size(6);
        box-shadow: 0 0 10px 0 transparent;
        transition: padding 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), box-shadow 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        
        padding-top: 10px;
        padding-bottom: 10px;

        @include media-up(xxl) {
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }
    .header-bottom-left {
        display: flex;
        align-items: center;
    }
    .header-bottom-center{
        z-index: 10;
    }
    .link-follow {
        display: flex;
        font-size: font-size(icon);
        li {
            margin-right: 15px;
        }

        span {
            display: none;
        }
    }
}

body.is-scrolled, #index.is-scrolled {
    #header {
        box-shadow: $box-shadow;
        @include media-up(xl) {
            .logo {
                transform: scale(0.8);
            }
        }
        .header-bottom {
            padding-top: 5px;
            padding-bottom: 5px;
            @include media-up(xxl) {
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
    }
}

#_desktop_social {
    margin-right: 20px;
}

.top-header-mobile {
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    align-items: center;
    border: solid $gray-1;
    border-width: 1px 0 1px;
    #_mobile_static {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        display: none;
    }
    #_mobile_social {
        width: 20%;
    }
    
    #_mobile_user_info {
        width: 80%;
        text-align: right;
    }
}
#_mobile_currency_selector, #_mobile_language_selector {
    width: 50%;
    float: left;
    .selector-mobile {
        .dropdown-menu {
            position: absolute;
            left: 0;
            width: 100%;
            box-shadow: $box-shadow;
        }
        button {
            i {
                right: 10px;
            }
        }
    }
}

#menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .menu-line {
        display: block;
        background: $black;
        height: 1px;
        width: 20px;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        &:nth-child(2) {
            width: 80%;
        }
    }
}

.user-info-block {
    white-space: nowrap;
}

.header-shop-actions {
    display: flex;
}
#header .header-bottom {
    .row {
        display: flex;
        align-items: center;
    }
    &.header-checkout {
        .row {
            flex-wrap: wrap;
        }
        .header-text {
            margin: 0;
        }
        @media (max-width: 991px) {
            padding-top: 0;
            padding-bottom: 0;
            .header-bottom-left, .header-bottom-right {
                justify-content: center;
            }
            .logo {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
            .header-bottom-left {
                padding-top: 5px;
                padding-bottom: 5px;
                background-color: $gray-1;
                
            }
        }
    }
}
.header-bottom-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header-text {
    margin-right: 30px;
    p {
        margin-bottom: 0;
    }
    b,strong {
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
    }
}

.header-top {
    font-size: font-size(6);
}

.blockcart {
    .button_cart {
        a {
            .item_count {
                background: $secondary;
            }
        }
    }
}

#_desktop_megamenu {
    .pos-menu-horizontal {
        .menu-content {
            .menu-item {
                > a {
                    font-size: font-size(6);
                    color: $black;
                    i.icon-chevron-down {
                        margin: 0 0 0 2px;
                    }
                }
                &:hover {
                    > a {
                        color: $black;
                        opacity: 0.75;
                    } 
                }
            }
            .menu-dropdown {
                position: fixed;
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                border: none;
                box-shadow: 0 10px 10px 0 rgba($black, 0.05);
                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: $gray-1;
                    left: 0;
                }

                .pos-menu-row {
                    align-items: flex-start;
                    padding-top: 50px;
                    padding-bottom: 50px;
                    @media (min-width: 1140px) {
                        width: 1140px;
                        margin: 0 auto;
                    }
                    .submenu-item {
                        > a {
                            text-transform: uppercase;
                            font-weight: font-weight(bold);
                            font-size: font-size(6);
                            &:hover {
                                color: $gray-3;
                            }
                        }
                    }
                    .category-sub-menu {
                        margin-top: 10px;
                        a {
                            font-size: font-size(6);
                            color: $black;
                            &:hover {
                                color: $gray-3;
                                transform: translateX(5px)
                            }
                        }
                    }
                }
            }
        }

        .dropdown-sm {
            .column_title {
                text-transform: uppercase;
                font-weight: font-weight(bold) !important;
            }
            .submenu-item {
                > a {
                    text-transform: none !important;
                    font-weight: font-weight(base) !important;
                    &:hover {
                        color: $gray-3;
                    }
                }
            }
        }
        
    }
} 
#_mobile_megamenu {
    .pos-menu-horizontal {
        .menu-item {
            a {
                line-height: 50px;
                padding-top: 0;
                padding-bottom: 0;
                color: $black;
                &:hover, &:focus, &:active {
                    color: $black;
                }
            }
            .submenu-item {
                a {
                    background: $gray-1;
                    border-color: #dcdcdc;
                }
                .category-sub-menu {
                    li {
                        a {
                            background-color: #dcdcdc;
                            border-color: #c7c7c7;
                        }
                    }
                }
            }
            .icon-drop-mobile {
                i {
                    font-size: 24px;
                    color: $black;
                    height: 50px;
                    line-height: 50px;
                }
            }
        }
    }
}

#mobile_top_menu_wrapper {
    height: 100vh;
    width: 100%;
    max-width: none;
    box-shadow: $box-shadow;
    transform: translate(-100%);
    transition: transform 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), top 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &.is-active {
        transform: translate(0);
    }

    .open_menu + a {
        font-weight: bold !important;
    }
}

.open-menu {
    &:after {
        display: none;
        height: 100vh;
        width: 100%;
    }
}
.menu-close {
    background: $gray-1;
    color: $black;
    padding-right: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: auto;
    i {
        font-size: 28px;
    }
}

.lang-de{
    #header{
        .menu-content{
            .menu-hidden-element{
                display: none;
            }
        }
    }
}