.shop-features {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    // @include media-down(lg) {
    //     display: none !important;
    // }

    @include media-up(lg) {
        flex-wrap: nowrap;
    }
    
    &:before {
        content: '';
        width: 100vw;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: $gray-1;
        position: absolute;
        z-index: -1;
    }

    .shop-features-item {
        padding: 50px 15px 0 15px;
        width: 50%;
        font-size: font-size(6);

        @include media-down(lg) {
            &:last-child {
                padding-bottom: 50px;
            }
        }


        @include media-up(lg) {
            padding: 25px 15px;
            width: 50%;
        }

        @include media-up(xxl) {
            padding: 50px 25px;
            font-size: font-size(5);
        }
        img {
            height: 45px;
            width: auto;
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 15px;
        }
        a {
            font-weight: font-weight(semi-bold);
            color: $secondary;
        }
    }
}