#product {

    /* Base layout */
    #content {
        width: 100%;
        max-width: 100%;
    }

    .primary_block {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;

        @include media-up(md) {
            margin-bottom: 80px;
        }
    }

    .product-block-left {
        width: 100%;

        @include media-up(md) {
            width: 50%;
        }
    }

    .product-block-right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include media-up(md) {
            width: 50%;
        }

        .product-block-wrapper {
            width: 100%;

            @include media-up(md) {
                width: 80%;
            }
        }
    }

    .product-minimal-quantity {
        margin: 0;
    }

}