.forgotten-password {
    padding: 4px
}

.forgotten-password .form-fields .center-email-fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
@media (max-width: 767px) {
    .forgotten-password .form-fields .center-email-fields {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
    .forgotten-password .form-fields .center-email-fields button {
        margin: 10px;
        width: calc(100% - 20px)
    }
}
.forgotten-password .form-fields .center-email-fields button {
    padding: .5rem 1rem;
    font-size: 1rem;
}
.forgotten-password .form-fields .email {
    padding-left: 0;
    padding-right: 0;
    width: 430px
}
@media (max-width: 767px) {
    .forgotten-password .form-fields .email {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%
    }
}
.forgotten-password .form-fields .email input {
    // height: 38px;
    height: 100% !important;
}
.forgotten-password .form-fields label.required {
    width: 130px
}
.send-renew-password-link {
    padding-left: 10px;
    padding-right: 10px
}
.renew-password {
    margin-left: 10px
}
.renew-password .email {
    padding-bottom: 50px;
    font-size: font-size(3);
    text-align: center;
}
.renew-password [type=submit] {
    width: 100%;
}