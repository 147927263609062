.form-control-label {
    padding-top: .625rem
}
.form-control {
    background-color: $gray-1;
    border: 1px solid $gray-1;
}
.form-control:focus {
    background-color: $white;
}

.form-control, .input-group .input-group-btn > .btn {
    padding: .5rem 1rem;
    font-size: 1rem;
    line-height: 1.3;
}

.input-group {
    &:focus {
        outline: none;
    }
    .input-group-btn {
        & > .btn {
            &[data-action=show-password] {
                background-color: $gray-3;
                border: 1px solid $gray-3;
            }
        }
    }
}

.custom-checkbox input[type=checkbox]+span .checkbox-checked {
    margin: -.1rem -0.05rem;
}
.custom-checkbox {
    a {
        color: $secondary;
    }
}
